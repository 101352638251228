@font-face {
	font-family: 'poppins';
	src: local('poppins'), url(./fonts/poppins-regular-webfont.ttf) format('truetype');
}

@font-face {
	font-family: 'avenir';
	src: local('avenir_roman'), url(./fonts/AvenirLTStd-Roman.otf);
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'avenirbold';
	src: local('avenir_bold'), url(./fonts/AvenirLTStd-Black.otf);
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: 'proxima_novaregular';
    src: local('proxima_novaregular'), url(./fonts/proxima_nova_regular-webfont.eot);
    src: local('proxima_novaregular'), url(./fonts/proxima_nova_regular-webfont.eot?#iefix) format('embedded-opentype'),
         local('proxima_novaregular'), url(./fonts/proxima_nova_regular-webfont.woff2) format('woff2'),
         local('proxima_novaregular'), url(./fonts/proxima_nova_regular-webfont.woff) format('woff'),
         local('proxima_novaregular'), url(./fonts/proxima_nova_regular-webfont.ttf) format('truetype'),
         local('proxima_novaregular'), url(./fonts/proxima_nova_regular-webfont.svg#proxima_novaregular) format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'proxima_novasemibold';
    src: local('proxima_novasemibold'), url(./fonts/proxima_nova_semibold-webfont.eot);
    src: local('proxima_novasemibold'), url(./fonts/proxima_nova_semibold-webfont.eot?#iefix) format('embedded-opentype'),
         local('proxima_novasemibold'), url(./fonts/proxima_nova_semibold-webfont.woff2) format('woff2'),
         local('proxima_novasemibold'), url(./fonts/proxima_nova_semibold-webfont.woff) format('woff'),
         local('proxima_novasemibold'), url(./fonts/proxima_nova_semibold-webfont.ttf) format('truetype'),
         local('proxima_novasemibold'), url(./fonts/proxima_nova_semibold-webfont.svg#proxima_novasemibold) format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'proxima_novabold';
    src: local('proxima_novabold'), url(./fonts/proxima_nova_bold-webfont.eot);
    src: local('proxima_novabold'), url(./fonts/proxima_nova_bold-webfont.eot?#iefix) format('embedded-opentype'),
         local('proxima_novabold'), url(./fonts/proxima_nova_bold-webfont.woff2) format('woff2'),
         local('proxima_novabold'), url(./fonts/proxima_nova_bold-webfont.woff) format('woff'),
         local('proxima_novabold'), url(./fonts/proxima_nova_bold-webfont.ttf) format('truetype'),
         local('proxima_novabold'), url(./fonts/proxima_nova_bold-webfont.svg#proxima_novabold) format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'proxima_novaextrabold';
    src: local('proxima_novaextrabold'), url(./fonts/proxima_nova_extrabold-webfont.eot);
    src: local('proxima_novaextrabold'), url(./fonts/proxima_nova_extrabold-webfont.eot?#iefix) format('embedded-opentype'),
         local('proxima_novaextrabold'), url(./fonts/proxima_nova_extrabold-webfont.woff2) format('woff2'),
         local('proxima_novaextrabold'), url(./fonts/proxima_nova_extrabold-webfont.woff) format('woff'),
         local('proxima_novaextrabold'), url(./fonts/proxima_nova_extrabold-webfont.ttf) format('truetype'),
         local('proxima_novaextrabold'), url(./fonts/proxima_nova_extrabold-webfont.svg#proxima_novaextrabold) format('svg');
    font-weight: normal;
    font-style: normal;

}
body {
	width: 100%;
	font-style: normal;
	font-family: 'avenir' !important;
	line-height: 1.3;
	padding: 0;
	margin: 0;
}
h1,
h2,
h3 {
	height: auto !important;
	font-weight: bold !important;
	font-style: normal;
	margin-top: 0;
}
h4,
h5 {
	height: auto !important;
	font-weight: bold !important;
	font-style: normal;
	margin-top: 0;
}
a {
	font-weight: normal !important;
	color: #007C90;
	text-decoration: none;
}
p {
	margin-top: 0;
	line-height: 1.5 !important;
	white-space: pre-wrap;
}
ul li,
ol li {
	margin-bottom: 10px;
}
li ::marker {
	margin-right: 10px;
}
img {
	max-width: 100% !important;
}
form {
	width: 100%;
}
label {
	margin-left: 0 !important;
}
input,
select {
	font-weight: normal !important;
	border: none;
	padding: 13px 25px;
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
}
.forms input,
.forms textarea {
	font-size: 16px;
	line-height: 21px;
	background: #fff;
	border: 1px solid #cacaca;
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
}
.select-form button {
	border: 1px solid #cacaca;
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
	overflow: hidden;
}
.select-form button input {
	font-size: 16px;
	line-height: 21px;
	border: none !important;
	padding-right: 0px;
	margin-bottom: 0px;
}
.select-form button > div > div:last-of-type {
	margin-left: 0px;
	margin-right: 10px;
}
.select-form button > div > div:last-of-type svg {
	width: 18px;
	height: 18px;
}
.forms sup {
	color: #d20001;
}
.forms-group {
	border-bottom: 1px solid #cacaca;
	padding-bottom: 20px;
	margin-bottom: 30px;
}
input.display-only,
textarea.display-only {
	background-color: #f3f4f8;
	border: none;
}
textarea {
	width: 100% !important;
	min-height: 80px !important;
	font-weight: normal !important;
}
button {
	min-height: auto !important;
	text-align: center !important;
}
button:hover {
	outline: none !important;
	box-shadow: none !important
}
button.primary {
	line-height: 1 !important;
	border: none !important;
}
.button {
	min-height: auto !important;
	font-weight: bold !important;
	text-align: center !important;
	line-height: 1 !important;
	color: #fff !important;
	background-color: #007C90;
	border: 3px solid #007C90 !important;
	padding: 13px 25px !important;
	display: inline-block;
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
}
.button-outline {
	min-height: auto !important;
	font-weight: bold !important;
	text-align: center !important;
	line-height: 1 !important;
	color: #007C90 !important;
	background-color: none !important;
	border: 3px solid #007C90 !important;
	padding: 13px 25px;
	display: inline-block;
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
}
.button-outline-alt {
	min-height: auto !important;
	font-weight: bold !important;
	text-align: center !important;
	line-height: 1 !important;
	color: #535353 !important;
	background-color: none !important;
	border: 3px solid #535353 !important;
	padding: 13px 25px;
	display: inline-block;
	border-radius: 7px !important;
	-webkit-border-radius: 7px !important;
}
.button-round {
	min-height: auto !important;
	font-weight: bold !important;
	text-align: center !important;
	color: #767676 !important;
	background: #e4e5e7 !important;
	border: none !important;
	padding: 3.5px 5px !important;
	display: inline-block;
	border-radius: 50px !important;
	-webkit-border-radius: 50px !important;
}
.capitalize {
	text-transform: capitalize;
}
.uppercase {
	text-transform: uppercase;
}
.lowercase {
	text-transform: lowercase;
}
.align-center {
	text-align: center;
}
strong,
.bold {
	font-family: 'avenir';
}
.extra-bold {
	font-family: 'avenir';
}
.rounded-small {
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
	overflow: hidden;
}
.rounded {
	border-radius: 10px !important;
	-webkit-border-radius: 10px !important;
	overflow: hidden;
}
.rounded-medium {
	border-radius: 15px !important;
	-webkit-border-radius: 15px !important;
	overflow: hidden;
}
.no-border {
	border-top: none !important;
	border-right: none !important;
	border-bottom: none !important;
	border-left: none !important;
}
.no-padding {
	padding-top: 0px !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
	margin-left: 0px !important;
}
.no-margin {
	margin-top: 0px !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
	margin-left: 0px !important;
}
.spacer {
	content: " ";
	width: 100%;
	background-color: #ecf1f4;
	border: 10px solid #ecf1f4;
	display: block !important;
}
.width-30 {
	width: 30%;
}
.width-50 {
	width: 50%;
}
.width-75 {
	width: 75%;
}
.full-width {
	width: 100%;
}
.main-wrapper {
	min-height: 100vh !important;
	position: relative;
	overflow-x: hidden;
}
.wrapper {
	position: relative;
	margin: auto;
}
.container {
	width: 100%;
	min-width: unset !important;
	max-width: 1100px !important;
	margin: 0 auto;
	position: relative;
}
.box-display {
	border: 1px solid #cacaca;
	padding: 20px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.logo img {
	width: 100px;
}
header a,
.header-nav a {
	font-family: 'proxima_novasemibold';
	color: #000;
}
header nav button {
	margin: 12px !important;
}
header nav a {
	color: #000 !important;
}
header nav img {
	max-width: unset !important;
}
.drop-notification-list .notification-item:not(:first-child):before {
	content: " ";
	width: 100%;
	border-top: 1.5px solid #c7c9c8;
	position: relative;
}
.drop-notification-list .notification-item:hover {
	background-color: #fcf1d5;
}
.notification-item strong {
	color: #007C90;
}
.shopping-cart-layer {
	width: 480px;
	background-color: #fff;
}
.cart-item-info span {
	font-size: 16px;
}
.cart-item-name {
	font-weight: bold;
	color: #000 !important;
}
.cart-item-price {
	font-weight: bold;
	color: #db342b;
}
.cart-item-variant {
	font-size: 14px !important;
	color: #979797;
}
.cart-item-remove {
	padding: 0px !important;
}
.shopping-cart-layer .spacer {
	border-width: 8px;
}
.shopping-cart-layer-discount {
	color: #db342b;
}
.shopping-cart-layer-total {
	font-weight: bold;
	border-top: 1px solid #333;
	padding: 15px 0 5px 0;
}
.login-form,
.form-container > div > div {
	height: auto;
	min-height: unset !important;
}
.sidebar h4 {
	margin-bottom: 0px;
}
.sidebar > div {
	width: 100% !important;
}
.sidebar-tabs div[role="tabpanel"] {
	overflow: auto !important;
}
.sidebar-menu .sidebar-tabs button div {
	border: none !important;
	padding: 2px 7px;
	margin: 3px 5px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.sidebar-menu .sidebar-tabs button[aria-selected="true"] div {
	border: none;
	background-color: #2c63ca;
}
.sidebar-menu .sidebar-tabs button[aria-selected="true"] span {
	font-weight: normal;
	color: #fff;
	margin: 0px;
}
.sidebar-menu a {
	width: 100%;
	font-size: 12px;
	text-transform: uppercase;
	color: #000;
	padding: 10px 0px;
	padding-left: 40px;
}
.sidebar-menu svg {
	width: 15px;
	height: 15px;
	margin-right: 2px;
	fill: #000;
	stroke: #000;
}
.sticky-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
}
.mobile-nav-list {
	margin-top: 10vh;
}
.mobile-nav-list a {
	font-family: 'avenir';
	color: #000;
}
.language-switch a {
	color: #000;
}
.language-switch span {
	height: 18px;
}
.mobile-language-switch a {
	padding: 0 7px;
}
.mobile-language-switch a:not(:first-child) {
	border-left: 1px solid #000;
}
.home-slideshow {
	z-index: 0 !important;
}
.home-slide-content {
	text-align: center;
	padding: 0 12%;
}
.home-slide-content h1,
.home-slide-content p {
	margin-bottom: 0;
}
.home-slide-content p {
	font-family: 'avenir';
}
.home-slide-content button {
	min-width: 230px;
}
.home-announcement {
	text-align: center;
}
.home-inspiration {
	background-color: #000;
	margin-top: -4px;
}
.home-inspiration h3 {
	color: #fff;
}
.grid-photo {
	margin-bottom: 15px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	overflow: hidden;
	position: relative;
}
.label-discount {
	font-weight: bold;
	color: #fff;
	background-color: #db3539;
	padding: 8px 20px;
	-webkit-border-radius: 0 0 0 10px;
	border-radius: 0 0 0 10px;
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
}
.label-new {
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
	background-color: #007C90;
	padding: 8px 20px;
	-webkit-border-radius: 0 0 0 10px;
	border-radius: 0 0 0 10px;
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
}
.grid-item .product-name {
	font-family: 'avenir';
	color: #655146;
}
.normal-price {
	font-family: 'avenir';
}
.regular-price {
	font-size: 0.8em !important;
	color: #969696;
	text-decoration: line-through;
}
.special-price {
	font-family: 'avenir';
	color: #e03c3b;
}
.product-regular-price {
	color: #969696;
	text-decoration: line-through;
}
.product-special-price {
	font-family: 'avenir';
	color: #e03c3b;
}
.home-showcase {
	background-color: #fcfaee;
}
.home-garage-sale {
	background-color: #d3ecf0;
}
.home-garage-sale-content h3 {
	font-family: 'proxima_novaextrabold';
}
.grid-info .article-title {
	font-family: 'avenir';
	color: #373536;
	margin-bottom: 10px;
}
.grid-info .article-excerpt {
	color: #868686;
}
.read-more {
	font-weight: bold !important;
	text-decoration: underline !important;
}
.page-search-input {
	background-color: #ecedef !important;
	border: none !important;
}
.tags a {
	color: #919191;
	border: 1px solid #919191;
	padding: 8px 20px;
	border-radius: 25px;
	-webkit-border-radius: 25px;
}
.tags a:hover,
.tags a:active,
.tags a:focus {
	color: #fff;
	background-color: #007C90;
	border: 1px solid #007C90;
}
.grid-item.categories .category-name {
	font-family: 'avenir';
	text-align: center;
	color: #fff;
}
.stack-wrapper {
	position: relative;
	box-sizing: border-box;
}
.stack-wrapper > div {
	width: 100% !important;
}
.page-title-wrapper h1 {
	margin-bottom: 0px;
}
.page-title-wrapper span {
	margin-top: 5px;
}
.page-title-wrapper.thank-you {
	padding: 150px 0;
}
.pagination {
	margin-top: 50px;
}
/*.pagination li:not(:first-child) {
	margin-left: 15px;
}*/
.pagination li button {
	color: #868686;
	border: 1px solid transparent;
	padding: 5px;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	overflow: hidden;
}
.pagination li button svg {
	width: 12px;
	height: 12px;
	margin-top: -4px;
}
.pagination li:first-child button,
.pagination li:last-child button {
	border: 1px solid #333;
}
.pagination li button[aria-current="page"] {
	color: #fff;
	background-color: #007C90;
	border-color: #007C90;
}
.home-newsletter p {
	font-family: 'proxima_novaextrabold';
	line-height: 1.2 !important;
}
.home-newsletter input {
	border: none !important;
}
.home-newsletter button {
	font-size: 16px;
	font-weight: bold;
	border: none !important;
	padding: 13px 25px;
}
.formfield-row {
	min-height: unset !important;
	margin-bottom: 12px;
}
.formfield-row button > div {
	border: none !important;
}
.formfield-row div {
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.filter-button a {
	color: #555;
	border: 1px solid #cacaca;
	padding: 11px;
	display:  block;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.filter-color a {
	width: 30px;
	height: 30px;
	text-indent: -99999px;
	border: 2px solid #fff;
	display: inline-block !important;
	border-radius: 25px;
	-webkit-border-radius: 25px;
}
.filter-color a:active,
.filter-color a:focus {
	box-shadow: 0 0 0 1px #333;
}
.filter-color a.selected {
	box-shadow: 0 0 0 1px #333;
}
.filter-tags a {
	margin-bottom: 10px;
}
.product-image-sticker span {
	font-family: 'avenir';
	color: #fff;
}
.product-image-note {
	text-align: center;
	color: #fff;
	opacity: 0.7;
}
.product-image-note span {
	color: #fff;
}
.product-display-name {
	margin-bottom: 0px;
}
.product-display-price {
	font-size: 34px !important;
	font-weight: bold;
}
.product-attribute-color {
	border-bottom: 2px solid #dadada;
	padding: 20px 0;
}
.product-attribute-color span {
	line-height: 28px;
}
.attrtext {
	line-height: 10px !important;
}
.product-attribute-color a {
	width: 25px;
	height: 25px;
	text-align: center;
	text-indent: -99999px;
	border: 2px solid #fff;
	display: inline-block !important;
	border-radius: 15px;
	-webkit-border-radius: 15px;
}
.product-attribute-color a:active,
.product-attribute-color a:focus {
	box-shadow: 0 0 0 1px #333;
}
.product-attribute-color a.selected {
	box-shadow: 0 0 0 1px #333;
}
.color-beige {
	background-color: #eeda9d;
}
.color-fern {
	background-color: #899a66;
}
.product-attribute-size {
	border-bottom: 2px solid #dadada;
	padding: 20px 0 20px 0;
	margin-bottom: 10px;
}
.product-attribute-size span {
	line-height: 30px;
}
.product-attribute-size a {
	font-size: 0.9em;
	line-height: 30px;
	color: #878787;
	border:  1px solid #757575;
	padding: 0px 12px;
	display: inline-block !important;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.product-attribute-size a:active,
.product-attribute-size a:focus {
	font-family: 'avenir';
	color: #fff;
	background-color: #007C90;
	border-color: #007C90;
}
.layer-attribute-options .product-attribute-size,
.layer-attribute-options .product-attribute-color {
	border-bottom: none !important;
	padding: 10px 0 !important;
}
.layer-attribute-options span,
.layer-attribute-options .product-attribute-size a {
	font-size: 0.9em !important;
	line-height: 28px;
	padding: 0px 12px;
}
.product-display-dimension {
	margin-bottom: 20px;
}
.product-display-dimension img {
	width: 75%;
}
.btn-qty {
	border: 2px solid #737373 !important;
	padding: 2.5px 5px !important;
	border-radius: 50px;
	-webkit-border-radius: 50px;
}
.btn-qty svg {
	fill: #737373;
	stroke: #737373;
}
.btn-qty.add {
	color: #fff !important;
	background-color: #007C90 !important;
	border: 2px solid #007C90 !important;
}
.btn-qty.add svg {
	fill: #fff !important;
	stroke: #fff !important;
}
.cart-item-quantity .btn-qty {
	border: 2px solid #007C90 !important;
}
.cart-item-quantity .btn-qty svg {
	fill: #007C90;
	stroke: #007C90;
}
.product-display-action .add-to-cart {
	padding: 12px 50px;
}
.product-description {
	margin: 40px 0 20px 0;
}
.product-description p {
	line-height: 1.5;
}
.inspirations-block p {
	color: #868686;
}
.article-header {
	height: 400px;
	position: relative;
}
.article-header .page-title-wrapper {
	position: absolute;
	bottom: 10%;
}
.article-header .page-title-wrapper h1 {
	color: #fff;
	margin-bottom: 0px;
}
.masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -20px; /* gutter size offset */
	width: auto;
}
.masonry-grid-column {
	padding-left: 20px; /* gutter size */
	background-clip: padding-box;
}
.masonry-grid-column > div { /* change div to reference your elements you put in <Masonry> */
	margin-bottom: 20px;
}
.steps-meter {
	border-radius: 4px;
	-webkit-border-radius: 4px;
}
.grid-hover .grid-item {
	border-radius: 12px;
	-webkit-border-radius: 12px;
}
.grid-hover .grid-photo {
	margin-bottom: 0px !important;
}
.grid-hover .grid-item:hover {
	background-color: #000;
}
.grid-hover .grid-photo:hover {
	opacity: 0.7;
}
.checkout-page .button {
	padding: 13px 45px !important;
}
.steps-number a {
	width: 33.333333%;
	color: #6b6b6b;
	display: block;
}
.steps-number-item {
	position: relative;
}
.steps-number-item:after {
	content: " ";
	width: 100%;
	height: 4px;
	background-color: #c9c9c9;
	position: absolute;
	top: 20px;
	-webkit-transition-property: color,background-color,border-color,box-shadow;
    transition-property: color,background-color,border-color,box-shadow;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
}
.steps-number-item-label {
	font-weight: bold;
	text-align: center;
	position: relative;
	z-index: 2;
}
.steps-number-item-label button {
	width: 45px;
	line-height: 45px;
	color: #6b6b6b !important;
	background-color: #c9c9c9 !important;
	border: none;
	padding: 0px;
	margin-bottom: 10px;
	border-radius: 50px;
	-webkit-border-radius: 50px;
}
.steps-number a:hover,
.steps-number a:active,
.steps-number a:focus {
	color: #007C90;
}
.steps-number a:hover .steps-number-item:after,
.steps-number a:active .steps-number-item:after,
.steps-number a:focus .steps-number-item:after {
	background-color: #007C90;
}
.steps-number a:hover button,
.steps-number a:active button,
.steps-number a:focus button {
	color: #fff !important;
	background-color: #007C90 !important;
}
.checkout-item-photo,
.checkout-item-photo img {
	width: 50px;
	border-radius: 7px;
	-webkit-border-radius: 7px;
}
.checkout-options-item {
	font-weight: normal;
	color: #000;
	background-color: #ededed;
	padding: 12px 13px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.checkout-options-item p {
	font-weight: normal;
}
.checkout-options-item p:last-child {
	margin-bottom: 0px !important;
}
.checkout-options-item a {
	font-weight: bold !important;
	text-decoration: underline !important;
}
.checkout-options-item a:hover {
	text-decoration: none !important;
}
.link-to-layer {
	font-weight: bold !important;
	text-decoration: underline !important;
	color: #000 !important;
}
.link-to-layer:hover {
	text-decoration: none !important;
}
.payment-step-summary {
	font-weight: bold;
}
.payment-step-total {
	border-top: 2px solid #cacaca;
	padding-top: 20px;
	margin-top: 5px;
	margin-bottom: 10px;
}
.thank-you-buttons button {
	padding: 13px 50px;
}
.signup-link a {
	text-decoration: underline;
}
.select-birthday-date {
	width: 20%;
}
.select-birthday-month {
	width: 50%;
}
.select-birthday-year {
	width: 24%;
}
.page-stack {
	min-height: 100vh !important;
}
.account-panel {
	background-color: #ffdf79;
	padding: 28px;
}
.account-panel h3,
.account-panel h4 {
	margin-bottom: 0px;
}
.panel-menu-item {
	border-bottom: 1px solid #c29540 !important;
	padding: 20px 0px !important;
}
.panel-menu-item:last-child {
	border-width: 0px !important;
	padding-bottom: 0px !important;
}
.panel-menu-item a {
	font-weight: bold !important;
	color: #333 !important;
}
.account-panel-menu a:hover {
	box-shadow: none !important;
}
.my-order-list > div,
.my-warranties-list > div {
	width: 100%;
}
.my-order-tab > div,
.my-warranties-tab > div {
	border-bottom: 3px solid transparent;
}
.my-order-tab[aria-selected="true"] > div,
.my-warranties-tab[aria-selected="true"] > div {
	border-bottom: 3px solid #333;
}
.my-order-tab span,
.my-warranties-tab span {
	font-size: 16px;
	color: #999;
}
.my-order-tab[aria-selected="true"] span,
.my-warranties-tab[aria-selected="true"] span {
	font-family: 'proxima_novaextrabold';
	color: #333;
}
.my-order-data:not(:first-of-type),
.my-warranties-data:not(:first-of-type) {
	border-top: 2px solid #ccc;
}
.my-order-id a {
	color: #333;
}
.order-status {
	color: #007C90;
}
.order-status svg {
	stroke: #007C90;
}
.order-status.alert {
	font-weight: bold;
	color: #db342b;
}
.order-status.alert svg {
	stroke: #db342b;
}
.my-order-item-variant,
.my-warranties-item-variant {
	color: #979797;
}
.my-warranty-info {
	padding: 15px 20px;
	background-color: #d9ebef;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.my-warranty-info.expired {
	background-color: #d0d0d0;
}
.my-warranty-info a {
	text-decoration: underline !important;
}
.my-order-item-totals {
	font-weight: bold;
}
.invoice-content-row {
	border-bottom: 2px solid #cacaca;
	padding-bottom: 15px;
	margin-bottom: 15px;
}
.invoice-display-content .invoice-content-row:last-of-type {
	border-bottom: none !important;
}
.invoice-extra-item {
	color: #585858;
	background-color: #ededed;
	padding: 20px;
	margin-bottom: 15px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}
.invoice-extra-item p:last-child {
	margin-bottom: 0px;
}
.invoice-display-total {
	border-top: 2px solid #cacaca;
	padding-top: 15px;
	margin-top: 5px;
}
.invoice-addresses-item {
	background-color: #ffedbb;
	padding: 20px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
}
.invoice-addresses-item.shipping {
	background-color: #ffe699;
}
.addresses-title {
	font-weight: bold;
	margin-bottom: 10px;
}
.addresses-phone {
	font-weight: bold;
	margin-top: 10px;
}
.faq-list > div:nth-of-type(odd) {
	background-color: #f0f0f0;
	border-radius: 7px;
	-webkit-border-radius: 7px;
}
.faq-list-item {
	padding: 20px 10px 20px 25px;
}
.faq-list-item h4 {
	margin: 0px;
}
.faq-list-item-content {
	padding-left: 63px;
	padding-bottom: 20px;
	padding-right: 63px;
}
.sticky-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 9;
}
.sticky-footer-wrapper {
	width: 100%;
	min-width: unset !important;
	max-width: 1100px !important;
	background-color: #fff;
	padding: 15px;
	margin: 0 auto;
	position: relative;
}
.form-style {
	background-color: #fff;
	padding: 30px;
	border-radius: 10px !important;
	-webkit-border-radius: 10px !important;
	overflow: hidden;
}
.form-style h2 {
	margin-bottom: 0px;
}
.form-style p {
	text-align: center;
	margin: 5px 0 15px 0;
}
.form-style-label {
	font-family: 'avenir';
	margin-bottom: 7px;
}
.form-style span,
.form-style button {
	font-size: 16px;
}
.form-style input {
	margin-bottom: 5px;
}
.page-footer {
	color: #fff;
	background-color: #769399;
}
.page-footer a {
	color: #fff !important;
}
.page-footer p {
	line-height: 1.5;
}
.page-footer .logo img {
	width: 100%;
	height: auto !important;
}
.footer-newsletter p {
	margin-bottom: 5px;
}
.footer-newsletter input {
	font-size: 16px;
	line-height: 21px;
	color: #fff;
	background-color: #547175;
	border-color: #547175;;
}
.footer-newsletter button {
	padding: 10px 30px !important;
}
.copyright {
	border-top: 2px solid #fff;
}
.main-wrapper.upspace-dream .page-title-wrapper h1,
.main-wrapper.upspace-dream .page-title-wrapper span {
	color: #fff;
}
.main-wrapper.upspace-dream .page-footer {
	color: #4e4e4e;
	background-color: #fff;
}
.main-wrapper.upspace-dream .page-footer a {
	color: #4e4e4e !important;
}
.main-wrapper.upspace-dream .page-footer .logo {
	margin-bottom: 60px;
}
.main-wrapper.upspace-dream .page-footer h4 {
	color: #6494ff;
}
.main-wrapper.upspace-dream .footer-newsletter input {
	color: #4e4e4e;
	background-color: #d0d0d0;
	border-color: #d0d0d0;
}
.main-wrapper.upspace-dream .footer-newsletter button {
	background: #6494ff !important;
	background-color: #6494ff !important;
	border-color: #6494ff !important;
}
.main-wrapper.upspace-dream .page-footer .copyright {
	border-color: #4e4e4e;
}
.layer-box {
	position: relative;
	border-radius: 12px !important;
	-webkit-border-radius: 12px !important;
}
.layer-box .close-button {
	background-color: #fff;
	position: absolute;
	top: 25px;
	right: 27px;
}
.layer-box-content {
	overflow-y: auto;
}
.layer-box-content h4 {
	margin-bottom: 0px;
}
.Toastify {
	z-index:21;
}
.mobile-visible {
	display: none;
	visibility: hidden;
}
:root {
	--swiper-theme-color: #007C90 !important;
}
.swiper-container {
	width: 100%;
	overflow: visible !important;
}
.swiper-button-next,
.swiper-button-prev {
	width: 35px !important;
    height: 35px !important;
    background-color: #333;
    border-radius: 30px;
	-webkit-border-radius: 30px;
	top: 30% !important;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	font-size: 15px !important;
    font-weight: bold;
	color: #fff !important;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: -13px !important;
	top: 48% !important;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	right: -13px !important;
	top: 48% !important;
}
.product-slideshow .swiper-button-prev,
.product-slideshow .swiper-container-rtl .swiper-button-next {
	left: 13px !important;
	opacity: 0.3;
}
.product-slideshow .swiper-button-next,
.product-slideshow .swiper-container-rtl .swiper-button-prev {
	right: 13px !important;
	opacity: 0.3;
}
.product-slideshow .swiper-button-prev:hover,
.product-slideshow .swiper-button-next:hover {
	opacity: 1;
}
.product-display-dimension .swiper-button-prev,
.product-display-dimension .swiper-container-rtl .swiper-button-next {
	left: 0 !important;
	opacity: 0.3;
}
.product-display-dimension .swiper-button-next,
.product-display-dimension .swiper-container-rtl .swiper-button-prev {
	right: 0 !important;
	opacity: 0.3;
}
.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 25px !important;
}
.swiper-pagination-bullet {
	width: 10px !important;
	height: 10px !important;
	background: #fff !important;
	opacity: .9 !important;
}
.swiper-pagination-bullet-active {
	background: #007C90 !important;
}
.out-of-stock {
	color: #aaa !important;
	border: 3px solid #aaa !important;
}
.detail-out-of-stock {
	color: #aaa !important;
	border: 3px solid #aaa !important;
	background: none !important;
}
.checkbox-static {
	font-weight: bold !important;
}
.checkbox-static label div div {
	border: solid 2px #000 !important
}
.checkbox-static svg {
	stroke:#000 !important;
}
.wraptext {
	white-space: pre-wrap;
}

.hidden-element {
	display: none !important;
}

.va-selected {
	border: 10px solid #FF5F1F;
	border-radius: 10px;
}

.imgdimwidth {
	width: 100% !important;
}

@media screen and (min-width: 769px) {
	.home-slide-content p {
		font-size: 1.2em;
	}
	.home-inspiration h3 {
		font-size: 30px;
	}
	.home-showcase button {
		min-width:  270px;
	}
	.home-garage-sale-content button {
		width: fit-content !important;
	}
	.home-newsletter p {
		font-size: 30px;
		text-align: center;
		padding: 0 30%;
		margin: 0 0 15px 0;
	}
	.home-newsletter .newsletter-form {
		padding: 0 20%;
	}
	.product-display {
		width: 55%;
		border-radius: 15px !important;
		-webkit-border-radius: 15px !important;
		overflow: hidden;
	}
	.grid-item .product-name {
		font-size: 18px;
	}
	.page-banner {
		height: 60vh;
	}
	.page-title-wrapper h1 {
		font-size: 45px !important;
	}
	.article-header .page-title-wrapper h1 {
		width: 45%;
	}
	.article-display {
		width: 50%;
		padding-right: 2.5%;
	}
	.article-display img {
		border-radius: 10px !important;
		-webkit-border-radius: 10px !important;
		overflow: hidden;
	}
	.article-display-info {
		width: 50%;
		padding-left: 2.5%;
	}
	.account-panel {
		border-radius: 15px !important;
		-webkit-border-radius: 15px !important;
		overflow: hidden;
	}
	.my-order-tab {
		width: 25%;
	}
	.my-warranties-tab {
		width: 20%;
	}
	.my-order-tab > div,
	.my-warranties-tab > div {
		margin-left: 0px;
		margin-right: 0px;
	}
	.sticky-footer-wrapper {
		right: 8px;
	}
	.confirmation-form {
		margin-top: 80px;
		margin-bottom: 80px;
	}
	.form-style {
		width: 500px;
		padding: 30px;
		margin-top: 20px;
		margin-bottom: 40px;
	}
	.form-style p {
		padding: 0 15%;
	}
	.main-wrapper.upspace-dream .page-footer .logo img {
		width: 360px;
	}
	.main-wrapper.upspace-dream .page-banner {
		height: 75vh;
	}
	.layer-box {
		width: 500px;
		max-height: 80vh !important;
		padding: 30px;
	}

}

@media screen and (min-width: 1400px) {
	body {
		width: 1400px !important;
	}
}

@media screen and (max-width: 768px) {
	.mobile-visible {
		display: block;
		visibility: visible;
	}
	.mobile-hidden {
		width: 0px !important;
		display: none;
		visibility: hidden;
	}
	.container {
		width: 92%;
	}
	.forms input,
	.forms textarea,
	.select-form button input {
		padding: 8px 8px;
	}
	.select-form button > div > div:last-of-type {
		margin-right: 7px;
	}
	.select-form button > div > div:last-of-type svg {
		width: 15px;
		height: 15px;
	}
	.mobile-nav-layer {
		background-color: #ecd899 !important;
	}
	.header-mobile nav a,
	.header-mobile-layer nav a {
		padding: 5px;
	}
	.sidebar-tabs div[role="tabpanel"] {
		padding-bottom: 60px;
	}
	.select-birthday-date {
		width: 20%;
	}
	.select-birthday-month {
		width: 50%;
	}
	.select-birthday-year {
		width: 24%;
	}
	.home-slide-content {
		padding: 0 10%;
		margin-top: 30px;
	}
	.home-slide-content h1 {
		font-size: 30px;
		line-height: 34px;
		margin-bottom: 5px;
	}
	.home-slide-content p {
		margin-bottom: 8px;
	}
	.grid-item .grid-photo {
		margin-bottom: 10px;
	}
	.grid-item button {
		padding: 10px 25px;
	}
	.home-inspiration h3 {
		font-size: 30px;
		line-height: 34px;
	}
	.home-garage-sale {
		padding: 40px 0 90px 0 !important;
	}
	.home-garage-sale-content {
		text-align: center;
		padding: 0 17%;
	}
	.home-garage-sale-content button {
		min-width: 160px;
		margin: auto;
	}
	.home-articles button {
		min-width: 160px;
	}
	.article-grid .grid-photo {
		width: 36%;
	}
	.article-grid .grid-info {
		width: 58%;
		margin-left: 6%;
	}
	.grid-info .article-title {
		font-family: 'proxima_novaextrabold';
		font-size: 18px;
		margin-bottom: 5px;
	}
	.home-newsletter p {
		font-size: 22px;
		text-align: center;
		padding: 0 5%;
		margin: 0 0 15px 0;
	}
	.page-banner {
		height: 50vh;
	}
	.page-title-wrapper.garage-sale {
		text-align: center;
	}
	.page-title-wrapper.garage-sale h1 {
		margin-bottom: 15px;
	}
	.page-title-wrapper.thank-you {
		text-align: center;
	    padding: 50px 0;
	}
	.page-title-wrapper.thank-you img {
		width: 80%;
		margin: 0 auto 30px auto;
	}
	.thank-you-buttons {
		text-align: center;
	}
	.thank-you-buttons button {
		padding: 13px 30px !important;
	}
	.page-stack.confirm-payment,
	.page-stack.thank-you {
		background: #cbd0e4 !important;
	}
	.form-style {
		width: 90% !important;
		padding: 20px;
		margin-top: 40px;
		margin-bottom: 60px;
	}
	.form-style p {
		font-size: 15px;
		padding: 0;
		margin: 10px 0 20px 0;
	}
	.box-rounded-mobile {
		background-color: #fff;
		margin-top: -20px;
		border-radius: 20px 20px 0 0 !important;
		-webkit-border-radius: 20px 20px 0 0 !important;
		box-shadow: 0 -1pxpx 10px 1px rgba(0,0,0,0.1);
		-webkit-box-shadow: 0 -1pxpx 10px 1px rgba(0,0,0,0.1);
		overflow: hidden;
		position: relative;
		z-index: 5;
	}
	.inspirations-block .button.primary {
		width: 75%;
		padding: 18px 45px !important;
	}
	.main-wrapper.upspace-dream .page-banner {
		height: 55vh;
	}
	.main-wrapper.account .header-mobile {
		background-color: #ffdf79;
	}
	.main-wrapper.account .account-panel {
		margin-top: -1px;
	}
	.panel-menu-item {
		border-color: #cacaca !important;
	}
	.panel-menu-item:last-child {
		padding-bottom: 20px !important;
	}
	.logout-mobile {
		font-family: 'avenir';
		line-height: 21px !important;
		color: #db342b !important;
		text-decoration: underline !important;
	}
	.layer-box-content {
		max-height: 70vh;
		padding: 0 25px;
		margin: 25px 0;
	}
	.my-order-data span,
	.my-warranties-data span {
		font-size: 16px;
		line-height: 20px;
	}
	.my-order-item-info {
		padding-top: 5px;
	}
	.my-order-tab-content,
	.my-warranties-tab-content,
	.edit-profile-wrapper {
		min-height: 320px !important;
	}
	.product-display-info,
	.edit-profile-wrapper {
		background-color: #fff;
		padding-top: 18px !important;
		margin-top: -18px;
		-webkit-border-radius: 15px 15px 0 0;
		border-radius: 15px 15px 0 0;
		overflow: hidden;
		z-index: 7;
	}
	.my-order-display,
	.my-warranties-display {
		background-color: #fff;
		margin-top: -18px;
		-webkit-border-radius: 15px 15px 0 0;
		border-radius: 15px 15px 0 0;
		overflow: hidden;
		z-index: 7;
	}
	.footer-newsletter button {
		width: 37% !important;
		padding: 10px 15px !important;
	}
	.layer-attribute-options {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 480px) {
	.width-30,
	.width-50,
	.width-75 {
		width: 100%;
	}
	.mobile-floating {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	.logo img {
		width: 60px;
	}
	.home-announcement span {
		font-size: 0.8em !important;
	}
	.drop-notification-mobile {
		border-top: ;
	}
	.product-display-name {
		color: #fff;
	}
	.product-info-mobile,
	.product-display-information,
	.my-order-tab-content,
	.my-warranties-tab-content {
		padding: 0px 4%;
	}
	.product-display-price {
		font-size: 28px !important;
	}
	.product-attributes-option {
		background-color: #fef9f3;
		padding: 0px 4%;
		margin: 18px 0;
	}
	.product-attribute-color {
		padding-bottom: 15px;
	}
	.product-attribute-size {
		border-bottom: none;
		padding-top: 15px;
		margin-bottom: 0px;
	}
	.product-attribute-color span,
	.product-attribute-size span {
		width: 12%;
	}
	.product-description {
		margin: 30px 0 10px 0;
		padding: 0px 4%;
	}
	.article-display button {
		padding: 5px 10px;
		border-radius: 7px;
		-webkit-border-radius: 7px;
	}
	.article-display-info {
		padding: 12px 4%;
	}
	.account-panel {
		padding-bottom: 50px;
	}
	.setting-mobile {
		padding: 0px !important;
	}
	.my-order-data,
	.my-warranties-data {
		padding: 15px 0px !important;
	}
	.invoice-display-header {
		margin-bottom: 5px;
	}
	.invoice-display-total {
		margin-bottom: 10px;
	}
	.invoice-addresses-item {
		width: 100%;
	}
	.thank-you-buttons button {
		width: 50%;
		padding: 13px 5px !important;
	}
	.page-footer {
		padding: 40px 4% 15px 4%;
	}
}